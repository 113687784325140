import React, { createRef, useState, useEffect } from "react"
import { Box, Flex, Text } from "rebass"
import {
  motion,
  useViewportScroll,
  useSpring,
  useTransform,
  useMotionTemplate,
} from "framer-motion"
import { useInView } from "react-intersection-observer"

const Feature = ({ theme, titleSize, feature, featuresLength, index }) => {
  const ref = createRef()
  const [distanceFromTop, setDistanceFromTop] = useState(0)

  useEffect(() => {
    if (ref.current) {
      setDistanceFromTop(ref.current.getBoundingClientRect().top)
    }

    // setInterval(() => {
    //   if (ref.current) {
    //     setDistanceFromTop(ref.current.getBoundingClientRect().top)
    //   }
    // }, 1000)
  }, [ref, setDistanceFromTop])

  const BigTitle = ({ side, title, distanceFromTop }) => {
    const { scrollY } = useViewportScroll()

    const getElementStartPosition = () =>
      distanceFromTop +
      (typeof window !== `undefined` ? window.pageYOffset : 0) -
      (typeof window !== `undefined` ? window.innerHeight : 0)

    const getElementEndPosition = () =>
      distanceFromTop + (typeof window !== `undefined` ? window.pageYOffset : 0)

    const scrollX = useSpring(
      useTransform(
        scrollY,
        [getElementStartPosition(), getElementEndPosition() + 200],
        [side === "right" ? -200 : 200, 0]
      ),
      {
        damping: 12,
      }
    )

    const opacity = useSpring(
      useTransform(
        scrollY,
        [
          getElementStartPosition() +
            (typeof window !== `undefined` ? window.innerHeight / 3 : 0),
          getElementEndPosition(),
        ],
        [0.3, 1]
      ),
      {
        damping: 12,
      }
    )

    const blurValue = useSpring(
      useTransform(
        scrollY,
        [getElementStartPosition() - 100, getElementEndPosition() - 400],
        [5, 0]
      ),
      {
        damping: 12,
      }
    )

    const blur = useMotionTemplate`blur(${blurValue}px)`

    return (
      <motion.div
        style={{
          position: "absolute",
          fontWeight: 500,
          left: side === "right" ? 40 : "initial",
          right: side === "left" ? 40 : "initial",
          x: scrollX,
          filter: blur,
          opacity,
        }}
      >
        <Text
          fontSize={titleSize}
          lineHeight={1.2}
          /* className={css`
            background: -webkit-linear-gradient(#0bffc9, #5752ff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          `} */
          color="background"
        >
          {title}
        </Text>
      </motion.div>
    )
  }

  const Number = () => {
    const [ref, inView] = useInView({
      threshold: 0,
    })

    return (
      <motion.div
        ref={ref}
        animate={inView ? "open" : "closed"}
        variants={{
          open: {
            opacity: 1,
            transition: {
              delay: 0.1,
              type: "spring",
              damping: 20,
              stiffness: 280,
            },
          },
          closed: {
            opacity: 0,
          },
        }}
      >
        <Text
          fontSize={["90px", "200px"]}
          fontWeight="400"
          lineHeight={1.2}
        >{`0${index + 1}`}</Text>
      </motion.div>
    )
  }

  const Paragraphs = () => {
    const [ref, inView] = useInView({
      threshold: 0,
    })

    return (
      <motion.div
        ref={ref}
        animate={inView ? "open" : "closed"}
        variants={{
          open: {
            scale: 1,
            opacity: 1,
            transition: {
              delay: 0.2,
            },
          },
          closed: {
            scale: 0.8,
            opacity: 0,
          },
        }}
      >
        <Text>
          {feature.title.map((title, i) => (
            <Text
              key={i}
              as="span"
              fontSize={["22px", 3]}
              fontWeight="500"
              sx={{ display: "block", lineHeight: 1 }}
            >
              {title}
            </Text>
          ))}
        </Text>

        <Text mt={[5]} color="lightgray" sx={{ lineHeight: 1.3 }}>
          {/* {feature.description} */}
        </Text>
      </motion.div>
    )
  }

  return (
    <Flex
      sx={{
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: ["none", "block"],
          width: "25%",
          flexGrow: 1,
        }}
      />

      <Flex
        sx={{
          width: ["100%", "50%"],
          justifyContent: index % 2 === 0 ? "flex-start" : "flex-end",
        }}
      >
        <Flex
          ref={ref}
          sx={{
            position: "relative",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "right",
            width: "50%",
            p: [4, 7],
            borderRight: ["none", "1px solid #fff"],
            pb: featuresLength - 1 === index ? [8, 8] : undefined,
            ...(index % 2 === 0
              ? {
                  zIndex: 1,
                  backgroundColor: "orange",
                }
              : {}),
          }}
        >
          {index % 2 === 0 ? (
            <>
              <Number />

              <Paragraphs />
            </>
          ) : (
            <>
              {feature.bigTitle && (
                <BigTitle
                  side="left"
                  title={feature.bigTitle}
                  distanceFromTop={distanceFromTop}
                />
              )}
            </>
          )}
        </Flex>

        <Flex
          sx={{
            position: "relative",
            justifyContent: "center",
            flexDirection: "column",
            width: "50%",
            p: [4, 7],
            pb: featuresLength - 1 === index ? [8, 8] : undefined,
            ...(index % 2 !== 0
              ? {
                  zIndex: 1,
                  backgroundColor: "orange",
                }
              : {}),
          }}
        >
          {index % 2 !== 0 ? (
            <>
              <Number />

              <Paragraphs />
            </>
          ) : (
            <>
              {feature.bigTitle && (
                <BigTitle
                  side="right"
                  title={feature.bigTitle}
                  distanceFromTop={distanceFromTop}
                />
              )}
            </>
          )}
        </Flex>
      </Flex>

      <Box
        sx={{
          display: ["none", "block"],
          width: "25%",
          flexGrow: 1,
        }}
      />
    </Flex>
  )
}

const FeaturesY = ({ theme, titleSize = ["80px", "200px"], features = [] }) => {
  return (
    <Box backgroundColor={"orange"} py={[10]}>
      {features.map((feature, i) => (
        <Feature
          key={i}
          theme={theme}
          titleSize={titleSize}
          feature={feature}
          featuresLength={features.length}
          index={i}
        />
      ))}
    </Box>
  )
}

export default FeaturesY
